/* hh*/
body {
    max-width: 1920px;
    margin: 0 auto;
}
@media screen and (min-device-width: 1951px) and (max-device-width: 4000px) {
    body{ zoom:2;}
}
@media screen and (min-device-width: 1951px) and (max-device-width: 2500px) {
    body{ zoom:1.5;}
}

@media screen and (min-device-width: 1025px) and (max-device-width: 1950px) {
    #app .program-content .item {    flex: 0 0 25%;    margin-bottom: 20px;    max-width: 25%;}
    #app .gym .gymImages .gymItem {     min-width: 20%; width: 20%;}
    .gym.position-relative > div {width:60%;margin:0 auto;}
    #app .about .aboutThird {       width: 61.875vw;    margin: 0 16.19vw 0 16.9vw; padding:0;}
    #app .gym .gymImages div p{font-size:0.729vw}
    #app .gym .gymImages .gymSvgItems img{width:3.125vw}
    #app .gym .gymTitle{font-size:1.458vw;}
    #app .program .owl-item {    width: 16.042vw!important;}
    #app #program .flex-column.align-items-center{width:72.6%;}
    #app .imgFitness{height: 16.042vw;    max-width: 16.667vw;}
    #app .cardHeader{font-size:0.833vw;margin: 0.365vw auto;}
    #app .cardListLeft,#app .cardListRight{font-size:0.625vw; margin:0.313vw 0;  }
    #app .cardFooterText{font-size:0.625vw; }
    #app .cardFooterImg img{width:1.667vw; height:1.667vw;}
    #app .cardFooterImg {    max-height: 1.667vw;    max-width: 1.667vw;}
    #app .ellipse {    height: 2.500vw;        width: 2.500vw;}
    #app .ellipse svg{width:100%; height:100%;}
    #app .cardListRight svg, #app .cardFooter svg {width:0.833vw}
    #app  #program .program-header{font-size:1.458vw;     margin-bottom: 1.563vw;    margin-top: 3.646vw;}
    #app .cardFooter {    padding: .5rem!important;}
    #app .sectionMain .sectionText h1{font-size:2.292vw;}
    #app .sectionMain .btnNorm{    font-size: 0.625vw;    height: 3.125vw;    max-width: 11.615vw;}
    #app .subscribe h2{font-size:1.458vw;}
    #app .subscribe h6{font-size:0.729vw;}
    #app .subscribe .subscribe-item h3{font-size:1.250vw;}
    #app .subscribe .subscribe-item p{font-size:2.865vw}
    #app .subscribe .subscribe-item a{ font-size: 0.625vw;   width: 9.115vw!important; height:3.125vw;}
    #app .subscribe .subscribe-item{height:25.729vw;}
    #app .about .about-items{width:56.146vw;}
    #app .about .imgAbsolute .img1{width:20.125vw;}
    #app .about .aboutFirst h2,#app .about .aboutSecond h2{font-size:1.875vw;     line-height: 2.604vw;}
    #app .about .aboutFirst p{ font-size:0.833vw;line-height:1.146vw;}
    #app .about .opg-numbers .opg-number span{ font-size:1.563vw;}
    #app .about .imgAbsolute .img2{      width: auto;  right: 3.594vw;    top: 18.438vw;}
    #app .preview{width:29.635vw;}
    #app .about .opg-numbers .opg-number p{font-size:0.729vw;}
    #app .about .opg-numbers{ margin-bottom: 4.375vw; margin-top: 5.365vw;}
    #app .about .playButton{width:5.208vw; height:5.208vw;}
    #app .about .opg-numbers .opg-number{margin-bottom:2.292vw; }
    #app .about .opg-numbers .opg-number {max-width:19.792vw;}
    #app .about .aboutThird h2{font-size:1.458vw; padding:0; margin-bottom: 1.563vw;}
    #app .about .aboutComment2 p{font-size:0.833vw;line-height:1.146vw;}
    #app .about .aboutComment1 div img{width:4.115vw; height:4.115vw;}
    #app .custom-text-comment img {width:1.146vw;}
    #app .aboutThird .aboutThird-content .aboutComment1{ max-width:25%;}
    #app .aboutThird .aboutThird-content .aboutComment2{ max-width:75%;}
    #app .aboutThird .aboutThird-content{flex-direction: row; padding:0;}
    #app .expert h2{ font-size:1.458vw;}
    #app .expert h4, #app .expert .trainer h3{font-size:0.729vw;}
    #app .news h2{font-size:1.458vw;}
    #app .news .news-item h3{ font-size:0.938vw;}
    #app .seo h2{font-size:1.146vw;line-height:1.615vw}
    #app .seo p{ font-size:0.729vw;line-height:1.042vw;}
    #app .seo h3{font-size:1.042vw;}
    #app footer .footerNav ul a{font-size:0.573vw;}
    #app footer .footerClient a{ width:13.698vw; font-size:0.573vw;}
    #app footer .footerClient{ height:2.604vw;}
    #app footer .footerPromo h6, #app footer .footerPromo p{ font-size:0.625vw;}
    #app .mainNav .nav a{ font-size:0.573vw;}
    #app .expert .trainer{ width:12.2vw;}
    #app .mainNav .navClient a span{ font-size:0.625vw;}
    #app #news .col-md-9 {    flex: 0 0 72.6%;    max-width: 72.6%;}
    #app footer .footerNav, footer .line, footer .promodex{ flex: 0 0 72.6%;    max-width: 72.6%;}
    .seo {    margin: 70px auto;    padding: 0;    flex: 0 0 72.6%;    max-width: 72.6%;}
    #app  #expert .col-12.p-0.justify-content-center {   margin: 0 auto; flex: 0 0 72.6%;    max-width: 72.6%;}
    #app  #expert .col-12.p-0.justify-content-center .col-10 {    flex: 0 0 100%;    max-width: 100%;}
    #app .mainNav .navHome img{width:7.552vw; height: 2.969vw;}
    #app footer .footerNav .footer-main-link img{ width:8.073vw;}
    #app .mainNav .nav li:nth-of-type(5) { display:block!important;}
    #app .mainNav .nav .dropdown {    display: none!important;    margin-right: 35px;}
    #app .mainNav .nav li:nth-of-type(4) { display:block!important;}
    #app .information-content .personal-params-header h3{font-size:1.042vw;}
    #app h2{font-size:1.458vw}
    #app .information-content .personal-params-item p{font-size:0.729vw;}
    #app .achievements-content .items .item{min-width: 12.292vw;}
    #app .achievements-content .items .item h1{font-size:0.729vw; line-height:1.042vw;}
    #app .achievements-content .items .item p{ font-size:0.625vw; line-height:0.885vw;}
    #app .subscribe-content .subscribe-transform h3 { font-size:1.042vw;}
    #app .subscribe-content .subscribe-transform button{max-width: 12.5vw;    padding: 12px 0;   font-size: 0.573vw;}
    #app .sideNav .sideNavBottom h2{ font-size:14px;}
    #app .first-step form .social-form h2{font-size:0.729vw;}
    #app .first-step h1{ font-size:1.042vw;}
    #app .first-step form .terms .termsError {border: 0;height: 24px;left: 0;position: absolute;top: 30px;width:auto;z-index: 0;}
    #app .first-step form .social-form .social-btn-grp button{width:10.313vw; height:2.396vw;}
    #app .second-step h1{font-size:1.042vw;}
    #app .second-step form h2{font-size:0.729vw}
    #app .submit-group .next{ width:11.979vw; }
    #app .submit-group .next, .submit-group .prev{ font-size:0.573vw;}
    #app .third-step h1{font-size:1.042vw;}
    #app .third-step .personal-data .item .value h3{ font-size:2.292vw;}
    #app .third-step .personal-data .item .plus-minus-group span{ width:2.188vw; height:2.188vw;}


    /*
    11px font-size:0.573vw;
    14px font-size:0.729vw;
    20px font-size:1.042vw;
    42px font-size:2.188vw
    44px font-size:2.292vw
    */
}

@media (min-device-width: 1025px) and (max-device-width:1600px){
    #app .expert .trainer{ width:13.5vw;}
}

@media (min-device-width: 1025px) and (max-device-width: 1440px){
    #app .expert .trainer{ width:13vw;}
    #app .about .aboutThird {    width: 61.875vw;    margin: 0 16.19vw 0 23.9vw;    padding: 0;}

}

@media (max-width: 1200px){
    #app .mainNav .nav .dropdown {    display: block!important;    margin-right: 35px;}
    #app .mainNav .nav li:nth-of-type(5) { display:none!important;}
    #app .mainNav .nav li:nth-of-type(4) { display:none!important;}
}

@media (max-width: 1024px){

    #app .program-content .item {
        flex: 0 0 50%;
        margin-bottom: 20px;
        max-width: 50%;
    }
}

@media (max-width: 768px){
    #app .subscribe .subscribe-item:not(:last-child){ margin:0!important;}
    #app .subscribe .subscribe-item:not(:last-child) {    margin: 0 auto!important;}
}





@media (max-width: 375px){
    #app .program-content .item {    flex: 0 0 100%;    margin-bottom: 20px;    max-width: 100%;}
    #app #program .align-items-center.col-md-10.col-sm-12.col-12{ padding-right:0;}
    #app .about .opg-numbers .opg-number {    max-width: unset!important;    width: auto;    text-align: center; margin-bottom:20px;    display: block;}
    #app .about .opg-numbers .opg-number span {    margin-right: 0;    margin-bottom: 6px;  display:block;  text-transform: uppercase;}
    #app .about .aboutFirst h2, #app.about .aboutSecond h2{font-size:20px; line-height:28px; margin-bottom:20px;}
    #app .gym, #app .about .aboutFirst, #app .subscribe h6, #app .about .opg-numbers {    margin-top: 50px;}
    #app .subscribe h6 {    margin-top: 50px;    margin: 50px 15px 6px 15px;}
    #app .about .opg-numbers{margin-bottom:50px;}
    #app .news .news-item .news-item-content{ height:auto!important;    display: block;}
    #app .seo h2{ font-size:20px;    line-height: 28px; margin-bottom:20px;}
    #app .news .news-item .news-item-content p {    height: auto;    overflow: hidden;     margin: 0 0 20px 0;}
    #app .first-step h1{margin-bottom:30px;}
    #app .auth-wrapper .first-step .login-input .custom-form-group-inline {    margin: 0 auto 20px;}
    #app .first-step form .social-form h2 {    text-align: center;}
    #app .gymImages .gymSvgItems{ width:50px;}
    #app .gym .gymTitle {    margin-bottom: 14px!important;}
    #app #program .program-header { font-size:20px;    margin-top: 44px!important;margin-bottom: 20px;}
    #app .imgFitness {    height: 290px;}
    #app .custom-text-comment.disabled{ display:block;}
    #app .subscribe h2{margin: 0 15px;    margin-bottom: 20px;    font-size: 20px;    line-height: 28px;}
    /*#app .owl-nav,#app .owl-nav .owl-next, #app .owl-nav .owl-prev{ display:block;}
    */
    #app .subscribe .subscribe-item h3{ margin-top:35px; margin-bottom:8px; }
    #app .subscribe .subscribe-item p{ line-height:43.2px;}
    #app .subscribe .subscribe-item a {    margin-top: 12px; margin-left:0;    width: 183px!important;    height: 48px;}
    #app .subscribe .subscribe-item{  height:240px;}
    #app .justify-content-center {    justify-content: normal!important;}
    #app .about .aboutFirst p {    margin-bottom: 20px!important;}
    #app #player, .preview {    width: 345px;    height: 226px;}
    #app .about .playButton {  width: 63px;    height: 63px;}
    #app .about .aboutThird h2{ margin-bottom:20px; padding:0;font-size: 16px; line-height: 19.42px;}
    #app footer .footerPromo h6 {    margin: 0 0 17px;    margin-right: 6px;}
    #app #expert .col-10.p-0 {flex: 0 0 100%;  max-width: 100%;}
    #app #expert{padding-left: 15px;    padding-right: 0;    position: relative;    width: 100%;    overflow: hidden;}
    #app #expert .row.d-flex.justify-content-around.align-items-center{ margin:0;}
    #app #expert.expert .trainer{padding-left:20px;}
    #app #news .justify-content-center{ padding-right:0;}
    #app #expert.expert .expertRate{ margin-bottom:50px;}
    #app #news.news .newsAbsolute {    background: #000;    color: #fff;    display: flex;    flex-direction: column;    height: 58px;  justify-content: center;    position: absolute;    right: 0;    top: 0;    width: 74px;}
    #app #news.news .newsAbsolute h3 {font-size: 22px;font-weight: 700;line-height: 22px; margin-top: 11px;    margin-bottom: 4px;}
    #app .news .news-item p{ font-size:12px;}
    #app .seo.col-sm-12 .col-md-5.xol-sm-12.col-12{padding:0;}
    #app .seo.col-sm-12 .col-md-7.xol-sm-12.col-12{padding:0;}
    #app .seo.col-sm-12 .justify-content-between {    justify-content: space-between!important;    padding-top: 40px;}
    #app .seo.col-sm-12{margin:50px auto;}
    #app .seo .seo-img {    left: 15px;    position: absolute;    top: 0;}
    #app .seo p:first-of-type {    color: #999; }
    #app .seo h3{ margin-bottom:12px;   margin-top: 50px;}
    #app footer .footerNav .footer-main-link img {    height: 50px;    width: 127.19px;}
    #app footer .footerClient {width: 271px;background: #f0f712;display: flex;height: 48px;font-size: 11px;justif-content: center;}
    #app footer .line {    border-bottom: 1px solid #444;    margin-bottom: 20px;}
    #app footer .footerPromo .d-flex, #app footer .footerPromo h6, #app footer .footerPromo img{    margin-bottom: 10px; }
    #app footer .footerPromo p {    margin: 0 12px 10px 0;}
    #app footer .footerPromo .d-flex.align-items-center.flex-md-row.flex-column div img{margin-top: 2px;margin-right: 6px; width: 40px; height: 40px; margin-left: 6px;}
    #app footer{ padding-bottom:2px;}
    /*
    11px font-size:0.573vw;
    14px font-size:0.729vw;
    20px font-size:1.042vw;
    42px font-size:2.188vw
    44px font-size:2.292vw
    */

}
/* jj*/
